var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isModalOpenLocal)?_c('b-modal',_vm._b({attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-label":"Modal","aria-modal":""},on:{"close":function($event){_vm.isModalOpenLocal = false}},model:{value:(_vm.isModalOpenLocal),callback:function ($$v) {_vm.isModalOpenLocal=$$v},expression:"isModalOpenLocal"}},'b-modal',_vm.$attrs,false),[_c('div',{staticClass:"service_modal"},[_c('header',{staticClass:"w-100 mb-5 is-flex is-justify-content-space-between"},[_c('h1',[_vm._v("Service changes history")]),_c('h2',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(_vm.resultsInfo.results)+" results")])]),(_vm.displayServiceHistory)?_c('main',{staticClass:"w-100"},[_c('div',{staticClass:"is-grid w-100 mb-5",staticStyle:{"color":"var(--gray-dark) !important","grid-template-columns":"1fr 3fr 2fr 3fr 12fr 2fr 2fr 2fr 2fr"}},[_c('div',[_vm._v("Visible")]),_c('div',[_vm._v("Day")]),_c('div',[_vm._v("Dates")]),_c('div',[_vm._v("Location")]),_c('div',[_vm._v("Service")]),_c('div',[_vm._v("Level")]),_c('div',[_vm._v("Pax(s)")]),_c('div',[_vm._v("Cost")]),_c('div',[_vm._v("Price")])]),_vm._l((_vm.serviceHistoryRecords),function(serviceRecord){return _c('div',{key:serviceRecord.updated_date,staticClass:"my-4 history__item is-flex",staticStyle:{"position":"relative"}},[_c('b-tag',{staticStyle:{"position":"absolute","left":"0","top":"-0.5rem"},attrs:{"rounded":""}},[_c('strong',[_vm._v(_vm._s(serviceRecord.edition.user.name))]),_vm._v(" - "+_vm._s(_vm.getServiceEditionDatetime(serviceRecord))+" ")]),_c('ServiceRow',{attrs:{"service":serviceRecord,"fieldsToDisplay":[
            _vm.constants.SERVICE_ROW_FIELDS.VISIBLE,
            _vm.constants.SERVICE_ROW_FIELDS.DAY,
            _vm.constants.SERVICE_ROW_FIELDS.DATES,
            _vm.constants.SERVICE_ROW_FIELDS.LOCATION,
            _vm.constants.SERVICE_ROW_FIELDS.LEVEL,
            _vm.constants.SERVICE_ROW_FIELDS.NUM_PAX,
            _vm.constants.SERVICE_ROW_FIELDS.COST,
            _vm.constants.SERVICE_ROW_FIELDS.PRICE ]}})],1)}),(_vm.displayPagination)?_c('ResultsInfo',{attrs:{"resultsInfo":_vm.resultsInfo,"loadMoreItems":_vm.loadMoreServiceHistoryRecords}}):_vm._e()],2):_c('div',{staticClass:"w-100 is-flex is-flex-direction-column",staticStyle:{"gap":"0.5rem"}},[_c('b-skeleton',{attrs:{"height":"3.5rem","active":_vm.loading}}),_c('b-skeleton',{attrs:{"height":"3.5rem","active":_vm.loading}}),_c('b-skeleton',{attrs:{"height":"3.5rem","active":_vm.loading}})],1),(!_vm.displayServiceHistory && !_vm.loading)?_c('div',[_c('p',[_vm._v("No service history found")])]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }