var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isModalOpen)?_c('b-modal',_vm._b({attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-label":"Modal","aria-modal":""},on:{"close":function($event){_vm.isModalOpen = false}},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},'b-modal',_vm.$attrs,false),[_c('div',{staticClass:"service_move_massive_modal"},[_c('GlobalEvents',{on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keymap.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==49){ return null; }return _vm.keymap.apply(null, arguments)}]}}),_c('header',{staticClass:"w-100"},[_c('h1',{staticClass:"mb-2"},[_vm._v(" Do you want to move the following services as well? ")]),_c('p',{staticClass:"my-4",staticStyle:{"width":"100%","text-align":"left"}},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v("Tip")]),_vm._v(": Press 1 to move only this one ")])]),_c('main',[_c('div',{staticClass:"service_selected"},[_c('h3',{staticStyle:{"font-weight":"500"}},[_vm._v("Service Edited")]),_c('ServiceRow',{attrs:{"highlight":true,"service":_vm.updatedServiceEdited,"fieldsToDisplay":[
            _vm.constants.SERVICE_ROW_FIELDS.DAY,
            _vm.constants.SERVICE_ROW_FIELDS.DATES,
            _vm.constants.SERVICE_ROW_FIELDS.LOCATION,
            _vm.constants.SERVICE_ROW_FIELDS.PRICE ]}})],1),_c('section',{staticClass:"service_massive_options"},[_c('header',[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.updatedNextServices.length)+" Services to move")])]),_c('div',{staticClass:"service_num_days_diff"},[_c('span',[_vm._v("Move")]),_c('h3',[(!_vm.isAhead)?_c('IconArrowLeft'):_c('IconArrowRight'),_vm._v(" "+_vm._s(_vm.daysToMove)+" days ")],1)])]),_c('ul',{staticClass:"service_massive_list"},_vm._l((_vm.updatedNextServices),function(serviceOption){return _c('ServiceRow',{key:serviceOption.id,attrs:{"service":serviceOption,"fieldsToDisplay":[
              _vm.constants.SERVICE_ROW_FIELDS.DAY,
              _vm.constants.SERVICE_ROW_FIELDS.DATES,
              _vm.constants.SERVICE_ROW_FIELDS.LOCATION,
              _vm.constants.SERVICE_ROW_FIELDS.PRICE ]}})}),1)])]),_c('footer',{staticClass:"is-flex is-flex-direction-column service_move_massive_modal__footer"},[_c('p',{staticClass:"w-100 has-text-left my-2"},[(
            _vm.servicesWithEditedPrices.length > 0 &&
            !_vm.maintainServiceManualPrices
          )?_c('span',[_c('IconWarningCircle',{staticClass:"mr-1",staticStyle:{"fill":"#ff9316"}}),_c('span',{staticStyle:{"color":"#ff9316","font-size":"0.8rem"}},[_vm._v(" Services with edited prices will be overwritten ")])],1):_vm._e(),_c('b-checkbox',{staticClass:"mb-2 mt-1 w-100",model:{value:(_vm.maintainServiceManualPrices),callback:function ($$v) {_vm.maintainServiceManualPrices=$$v},expression:"maintainServiceManualPrices"}},[_vm._v(" Mantain services prices ")])],1),_c('div',{staticClass:"is-flex w-100",staticStyle:{"gap":"1rem"}},[_c('BaseButton',{attrs:{"action":"secondary"},on:{"click":_vm.onSecondaryActionClick}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("No, move only this one")])]),_c('BaseButton',{attrs:{"action":"principal","idElement":'confirmButton'},on:{"click":_vm.onContinueClick}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Yes, move all")])])],1)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }