<template>
  <b-tab-item label="PRICING">
    <div class="table-layout-auto tableEditPricing EditServiceSideBarBox mb-6">
      <div class="b-table">
        <div class="table-wrapper has-mobile-cards">
          <table class="table">
            <tbody class="is-block">
              <tr
                v-if="helpers.hasViewCostPricePermission(authData)"
                class="is-flex is-justify-content-center is-align-items-center py-1"
              >
                <td>Cost Unit</td>
                <td class="color-7">
                  {{ getCostCurrency(formItineraryService) }}
                  {{
                    formItineraryService.cost_unit ||
                    formItineraryService.cost ||
                    0
                  }}
                </td>
              </tr>

              <tr
                class="is-flex is-justify-content-center is-align-items-center py-1"
              >
                <td>Provider</td>
                <td>
                  {{ serviceSuppliers }}
                </td>
              </tr>

              <tr
                class="is-flex is-justify-content-center is-align-items-center py-1"
              >
                <td>
                  {{
                    helpers.getComplementTextByServiceType(
                      service.id_service_type
                    )
                  }}
                </td>
                <td>
                  {{ formItineraryService.days }}
                </td>
              </tr>

              <tr v-if="helpers.hasViewCostPricePermission(authData)">
                <td class="has-text-centered">Cost</td>
                <td class="color-7">
                  {{ getCostCurrency(formItineraryService) }}
                  {{ formItineraryService.cost || 0 }}
                </td>
              </tr>
              <tr
                class="is-flex is-justify-content-center is-align-items-center py-1"
              >
                <td>
                  {{
                    helpers.isAccommodationPackageService(
                      formItineraryService.id_service_type
                    )
                      ? "Price P. Night"
                      : "Sales Price / Unit"
                  }}
                </td>
                <td class="color-7">
                  {{ getSalesPriceCurrency(formItineraryService) }}
                  {{ formItineraryService.price_unit || "0.00" }}
                </td>
              </tr>
              <tr
                class="is-flex is-justify-content-center is-align-items-center pt-1 is-relative"
              >
                <td class="has-text-centered">Sales Price</td>
                <td v-if="hasViewSalePricePermission && hasNewPriceAvailable">
                  <AlertButton
                    v-if="hasViewSalePricePermission && hasNewPriceAvailable"
                    :label="
                      'Price is outdated. Click to use new price: ' +
                      getSalesPriceCurrency(formItineraryService) +
                      originalServicePrice
                    "
                    :text="
                      getSalesPriceCurrency(formItineraryService) +
                      originalServicePrice
                    "
                    :onButtonClick="updateServicePrice"
                  />
                </td>
                <td class="color-7" style="width: 60%">
                  <div
                    class="is-flex is-justify-content-center is-align-items-center"
                    style="height: 2rem; gap: 0.5rem"
                  >
                    <BaseInput
                      v-if="
                        hasEditSalePricePermission &&
                        !hideElementByCondition &&
                        !disableQuoteByStatus &&
                        !isServiceDatesRangeEdited
                      "
                      @input="emitUpdateForm('price', $event)"
                      :value="formItineraryService.price"
                      size="large"
                      type="number"
                    />
                    <span v-else>{{
                      formItineraryService.price || "0.00"
                    }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-tab-item>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "@/helpers";
import { serviceHelper } from "@/utils/service";
import { PERMISSION } from "@/constants";
import { USER_ROLE } from "@/constants";
import AlertButton from "@/components/_shared/AlertButton.vue";

export default {
  name: "EditServiceSideBarTabsPricing",
  components: {
    AlertButton,
  },
  data() {
    return {
      helpers: { ...helpers, ...serviceHelper },
    };
  },
  props: {
    isServiceDatesRangeEdited: {
      type: Boolean,
      default: false,
    },
    formItineraryService: {
      type: Object,
      default: () => {},
    },
    automaticPriceCalculate: {
      type: Boolean,
      default: true,
    },
    serviceFirstTime: {
      type: Boolean,
      default: false,
    },
    originalServicePrice: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    updateServicePrice() {
      if (
        !serviceHelper.isAccommodationPackageService(
          this.formItineraryService.id_service_type
        )
      ) {
        this.emitUpdateForm(
          "prices_costs_margins",
          this.formItineraryService?.prices_costs_margins
        );
      } else {
        const newRooms = serviceHelper.updateRoomPrices(
          this.formItineraryService.room,
          "fromNewPriceUnit"
        );
        this.emitUpdateForm("rooms_price", newRooms);
      }
    },
    getItineraryCurrencyCode() {
      const currency_code = this.itinerary?.data?.currency?.currency_code || "";
      return currency_code;
    },
    getItineraryCurrencySign() {
      const currency_sign = this.itinerary?.data?.currency?.currency_sign || "";
      return currency_sign;
    },
    getSalesPriceCurrency(formItineraryService) {
      if (formItineraryService.service_price === null)
        return `${this.getItineraryCurrencyCode()} ${this.getItineraryCurrencySign()}`;
      if (formItineraryService.service_price === undefined)
        return `${this.getItineraryCurrencyCode()} ${this.getItineraryCurrencySign()}`;
      const currency_sign =
        formItineraryService?.service_price[0]?.currency_sign ??
        this.getItineraryCurrencySign();
      const currency_code =
        formItineraryService?.service_price[0]?.currency_code ??
        this.getItineraryCurrencyCode();
      return `${currency_code} ${currency_sign}`;
    },
    getCostCurrency(formItineraryService) {
      if (formItineraryService.service_supplier === null)
        return `${this.getItineraryCurrencyCode()} ${this.getItineraryCurrencySign()}`;
      if (formItineraryService.service_supplier === undefined)
        return `${this.getItineraryCurrencyCode()} ${this.getItineraryCurrencySign()}`;
      const currency_sign =
        formItineraryService?.service_supplier[0]?.currency_sign ??
        this.getItineraryCurrencySign();
      const currency_code =
        formItineraryService?.service_supplier[0]?.currency_code ??
        this.getItineraryCurrencyCode();
      return `${currency_code} ${currency_sign}`;
    },
    emitUpdateForm(key, value) {
      this.$emit("update", [key, value]);
    },
  },
  computed: {
    ...mapGetters({
      itinerary: "itinerary/getItinerary",
      service: "services/getService",
      user: "auth/getAuthUserData",
      authData: "auth/getAuthData",
      disableQuoteByStatus: "itinerary/getDisableQuoteByStatus",
      hideElementByCondition: "itinerary/getHideElementByCondition",
    }),
    hasViewSalePricePermission() {
      return serviceHelper.hasAnyViewPermissions(
        this.authData,
        this.itinerary,
        [PERMISSION.VIEW_SALE_PRICE, PERMISSION.VIEW_PRICE_SEARCHED]
      );
    },
    hasNewPriceAvailable() {
      return this.originalServicePrice !== this.formItineraryService?.price;
    },
    serviceSuppliers() {
      const isAdmin = helpers.hasAnyRoles(
        this.authData.user,
        [USER_ROLE.ADMIN],
        true
      );
      return isAdmin
        ? serviceHelper.getServiceSuppliers(this.formItineraryService)
        : "";
    },
    hasEditSalePricePermission() {
      return !helpers.hasAnyRoles(
        this.authData.user,
        [USER_ROLE.TRAVEL_AGENT],
        false
      );
    },
  },
};
</script>
